const PortfolioCard = ({
  title,
  image,
  tecnologies,
  demo
}: {
  title: string;
  image: string;
  tecnologies: string[];
  demo: string;
}) => {
  return (
    <div className="group shape10 relative m-5 h-72 w-96 cursor-pointer overflow-hidden bg-slate-900 bg-opacity-70 transition-all group-hover:relative group-hover:h-auto">
      <span className="absolute z-50 mx-4 mb-2 mt-4 block text-xl font-bold text-white transition-all duration-700 group-hover:absolute group-hover:z-50">{title}</span>

      <div className={'absolute top-14 left-0 z-10 h-72 w-full transition-all duration-500 ease-in-out group-hover:top-0'}>
        <img
          src={image}
          alt=""
          className="relative top-0 left-0 z-10 h-72 object-cover"
        />
        <div className="absolute top-0 left-0 z-50 flex h-full w-full items-center justify-center bg-black opacity-0 transition-all duration-500 group-hover:opacity-90">

          <div className="flex">
            <a href={demo} target={'_blank'} className="mr-2 flex items-center rounded-md border border-gray-100 bg-slate-800 bg-opacity-90 px-3 py-1 font-bold text-white hover:bg-slate-900 hover:bg-opacity-100" rel="noreferrer">
              Visit{' '}
              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width={32} height={32}>
                <path fill="#fff" d="M19,14 L19,19 C19,20.1045695 18.1045695,21 17,21 L5,21 C3.8954305,21 3,20.1045695 3,19 L3,7 C3,5.8954305 3.8954305,5 5,5 L10,5 L10,7 L5,7 L5,19 L17,19 L17,14 L19,14 Z M18.9971001,6.41421356 L11.7042068,13.7071068 L10.2899933,12.2928932 L17.5828865,5 L12.9971001,5 L12.9971001,3 L20.9971001,3 L20.9971001,11 L18.9971001,11 L18.9971001,6.41421356 Z" fillRule="evenodd" />
              </svg>
            </a>
            {/* <a href="" className="mr-2 flex items-center rounded-md border border-gray-100 bg-slate-900 bg-opacity-70 px-3 py-1 font-bold text-white hover:bg-slate-900 hover:bg-opacity-100">
              Details
              <svg
                className="top-0 left-0 z-10 rounded-full bg-slate-800 bg-opacity-40"
                viewBox="0 0 24 24"
                width={32}
                height={32}
              >
                <path
                  fill="white"
                  d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
                />
              </svg>
            </a> */}
          </div>
        </div>
      </div>

      <div className="absolute left-0 -bottom-12 z-10 mt-4 flex w-full p-4 transition-all duration-700 group-hover:bottom-10">
        <div className="relative">
          <div className="absolute -bottom-32 -left-16 z-30 h-40 w-full rounded-2xl bg-slate-900 px-56 opacity-50 transition-all duration-700 group-hover:opacity-0"></div>
          <div className="absolute z-50 flex">
            {tecnologies.map((tecnology, index) => (
              <img
                src={`./langs/${tecnology}.webp`}
                alt={tecnology}
                className="mx-2 h-8 w-8"
                key={index}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PortfolioCard
