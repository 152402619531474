import SkillItem from './skill_item'

const SkillSection = () => {
  return (
    <section id='skills'>
        <div className="mx-auto mt-20 flex w-full max-w-4xl items-center justify-center">
          <div className="rounded-xl  p-4 text-center">
            <h1 className="mt-4 text-5xl font-bold text-white">Skills</h1>

            <h2 className="my-6 text-2xl font-bold text-white">
              Languages and frameworks
            </h2>
            <div className="flex flex-wrap justify-center">

            <SkillItem
                skill={'ReactJS'}
                level={'Basic'}
                url={'./langs/reactjs.webp'}
              />

              <SkillItem
                skill={'TypeScript'}
                level={'Intermediate'}
                url={'./langs/typescript.webp'}
              />

              <SkillItem
                skill={'JavaScript'}
                level={'Intermediate'}
                url={'./langs/javascript.webp'}
              />

              <SkillItem
                skill={'HTML'}
                level={'Intermediate'}
                url={'./langs/html.webp'}
              />

              <SkillItem
                skill={'NextJS'}
                level={'Basic'}
                url={'./langs/nextjs.webp'}
              />

              <SkillItem
                skill={'TailwindCSS'}
                level={'Basic'}
                url={'./langs/tailwindcss.webp'}
              />

              <SkillItem
                skill={'PHP'}
                level={'Intermediate'}
                url={'./langs/php.webp'}
              />

              <SkillItem
                skill={'Laravel'}
                level={'Intermediate'}
                url={'./langs/laravel.webp'}
              />
{/*
              <SkillItem
                skill={'Flutter'}
                level={'Basic'}
                url={'./langs/flutter.webp'}
              /> */}
            </div>

            <h2 className="my-6 text-2xl font-bold text-white">
            Tools and technologies
            </h2>
            <div className='flex flex-wrap justify-center'>
            <SkillItem
                skill={'Docker'}
                level={'Basic'}
                url={'./others/docker.webp'}
              />

            <SkillItem
                skill={'Github Actions'}
                level={'Basic'}
                url={'./others/gh.webp'}
              />

            <SkillItem
                skill={'Postman'}
                level={'Basic'}
                url={'./others/postman.webp'}
              />

            <SkillItem
                skill={'Visual Studio Code'}
                level={'Intermediate'}
                url={'./others/vscode.webp'}
              />
            </div>
          </div>
        </div>
    </section>
  )
}

export default SkillSection
