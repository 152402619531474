const AboutSection = () => {
  return (
    <section id="about">
        <div className="mx-auto flex w-full max-w-2xl items-center justify-center  pt-8 pb-16">
          <div className="rounded-xl  p-4 text-center">
            <h1 className="mt-4 text-5xl font-bold text-white">About Me</h1>
            <p className="mt-24 text-justify text-2xl text-white">
            IT Engineer with +3 years of experience in web development and +5 years in programming. Fast and continuous learning, analytical capacity, teamwork, self-taught in constant preparation. I am excited by the idea of facing challenges, accepting new challenges, being able to create incredible products and solutions with the skills I have acquired, I enjoy every moment that I program and learn about technology.
            </p>
          </div>
        </div>
        <div>
          <h2></h2>
        </div>
    </section>
  )
}

export default AboutSection
