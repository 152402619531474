import AboutSection from './components/about_section'
import ContactMeSection from './components/contactme_section'
import FloatingMenu from './components/floating_menu'
import FooterSection from './components/footer_section'
import HeadSection from './components/head_section'
import PortfolioSection from './components/portfolio_section'
import SkillSection from './components/skill_section'
import { activate, getRemoteConfig, getValue, fetchConfig } from 'firebase/remote-config'
import { initializeApp } from 'firebase/app'
import { useEffect, useState } from 'react'
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'

const app = initializeApp({
  apiKey: 'AIzaSyAQHj2ODmpUTyETwuYG5npfgKoSf9qv9ac',
  authDomain: 'crisvegadev-portfolio.firebaseapp.com',
  projectId: 'crisvegadev-portfolio',
  storageBucket: 'crisvegadev-portfolio.appspot.com',
  messagingSenderId: '581462855085',
  appId: '1:581462855085:web:295aacf33604f0842da524'
})

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6Letb3seAAAAANPG2hSxvtKvatzZEJzFbaGRRSin'),
  isTokenAutoRefreshEnabled: true
})

console.log(appCheck)

const remoteConfig = getRemoteConfig(app)

remoteConfig.defaultConfig = {
  contact_form_enable: 'yes',
  enable_pp: true
}

const contactFormEnable = getValue(remoteConfig, 'contact_form_enable')
const enablePP = getValue(remoteConfig, 'enable_pp')

function App () {
  const [contactFormEnabled, setContactFormEnabled] = useState(false)
  const [enablePPEnabled, setEnablePPEnabled] = useState(false)

  console.log(contactFormEnable)
  console.log(enablePP)

  useEffect(() => {
    fetchConfig(remoteConfig)
      .then(() => {
        activate(remoteConfig)
        setContactFormEnabled(contactFormEnable.asBoolean())
        setEnablePPEnabled(enablePP.asBoolean())
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  return (
    <div className='relative'>

      <script src="https://www.google.com/recaptcha/api.js"></script>

      <HeadSection ppIsEnabled={enablePPEnabled}/>

      <AboutSection />

      <SkillSection />

      <PortfolioSection />

      <ContactMeSection firebase={app} isEnabled={contactFormEnabled} />

      <FooterSection />

      <FloatingMenu isContactFormEnabled={contactFormEnabled}/>

    </div>
  )
}

export default App
