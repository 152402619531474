const InputItem = ({ type, placeholder, name, value, onChange }:{
    type: string,
    placeholder: string,
    name: string,
    value: string,
    onChange: (e: any) => void
}) => {
  return type === 'text' || type === 'email'
    ? (
        <div className="my-2 w-full px-4">
            <input
              name={name}
              className="w-full appearance-none rounded border border-none bg-slate-900 bg-opacity-90 p-3 text-xl leading-tight text-white placeholder-slate-200 focus:outline-none"
              id="name"
              type={type}
              placeholder={placeholder}
              autoComplete="off"
              required={true}
              value={value}
              onChange={(e: any) => onChange(e)}
            />
          </div>
      )
    : (
        <div className="w-full px-4 ">
            <textarea
                name={name}
                className="w-full appearance-none rounded border border-none bg-slate-900 bg-opacity-90 p-3 text-xl leading-tight text-white placeholder-slate-200 focus:outline-none"
                id="message"
                placeholder={placeholder}
                rows={8}
                autoComplete="off"
                required={true}
                value={value}
                onChange={(e: any) => onChange(e)}
            ></textarea>
        </div>
      )
}

export default InputItem
