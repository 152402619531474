import { Link } from 'react-scroll'

const FloatingMenu = ({ isContactFormEnabled }:{
    isContactFormEnabled: boolean
}) => {
  return (
        <div className='fixed inset-x-1/2 bottom-8 z-50 mx-auto flex w-auto justify-center lg:bottom-16'>
            <div className='flex w-96 rounded-xl  bg-cyan-900 bg-opacity-50 py-4 backdrop-blur-md'>

                <Link id="test" activeClass="bg-cyan-500" to="home" spy={true} smooth={true} duration={1500} className={'mx-4 cursor-pointer overflow-hidden rounded-full p-2 transition-all duration-500'} >
                    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" width={28} height={28}>
                        <path fill='#fff' d="M395.49,450H125.8a15,15,0,0,1-15-15V197a15,15,0,0,1,30,0V420H380.49V197a15,15,0,1,1,30,0V435A15,15,0,0,1,395.49,450Z" />
                        <path fill='#fff' d="M445.11,261.65a15,15,0,0,1-10.61-4.39L260.64,83.4,86.78,257.26a15,15,0,0,1-21.21-21.21L250,51.58a15,15,0,0,1,21.21,0L455.72,236.05a15,15,0,0,1-10.61,25.6Z" />
                        <path fill='#fff' d="M304.15,450h-87a15,15,0,0,1-15-15V305.48a15,15,0,0,1,15-15h87a15,15,0,0,1,15,15V435A15,15,0,0,1,304.15,450Zm-72-30h57V320.48h-57Z" />
                    </svg>
                </Link>

                <Link activeClass="bg-cyan-500" to="about" spy={true} smooth={true} duration={1500} className={'mx-4 cursor-pointer overflow-hidden rounded-full p-2 transition-all duration-500'}>
                    <svg id="Layer_1" version="1.1" viewBox="0 0 128 128" width={28} height={28}>
                        <path fill='#fff' d="M30,49c0,18.7,15.3,34,34,34s34-15.3,34-34S82.7,15,64,15S30,30.3,30,49z M90,49c0,14.3-11.7,26-26,26S38,63.3,38,49   s11.7-26,26-26S90,34.7,90,49z" />
                        <path fill='#fff' d="M24.4,119.4C35,108.8,49,103,64,103s29,5.8,39.6,16.4l5.7-5.7C97.2,101.7,81.1,95,64,95s-33.2,6.7-45.3,18.7L24.4,119.4z" />
                    </svg>
                </Link>

                <Link activeClass="bg-cyan-500" to="skills" spy={true} smooth={true} duration={1500} className={'mx-4 cursor-pointer overflow-hidden rounded-full p-2 transition-all duration-500'}>
                    <svg viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg" width={28} height={28}>
                        <path fill='#fff' d="M416 31.94C416 21.75 408.1 0 384.1 0c-13.98 0-26.87 9.072-30.89 23.18l-128 448c-.8404 2.935-1.241 5.892-1.241 8.801C223.1 490.3 232 512 256 512c13.92 0 26.73-9.157 30.75-23.22l128-448C415.6 37.81 416 34.85 416 31.94zM176 143.1c0-18.28-14.95-32-32-32c-8.188 0-16.38 3.125-22.62 9.376l-112 112C3.125 239.6 0 247.8 0 255.1S3.125 272.4 9.375 278.6l112 112C127.6 396.9 135.8 399.1 144 399.1c17.05 0 32-13.73 32-32c0-8.188-3.125-16.38-9.375-22.63L77.25 255.1l89.38-89.38C172.9 160.3 176 152.2 176 143.1zM640 255.1c0-8.188-3.125-16.38-9.375-22.63l-112-112C512.4 115.1 504.2 111.1 496 111.1c-17.05 0-32 13.73-32 32c0 8.188 3.125 16.38 9.375 22.63l89.38 89.38l-89.38 89.38C467.1 351.6 464 359.8 464 367.1c0 18.28 14.95 32 32 32c8.188 0 16.38-3.125 22.62-9.376l112-112C636.9 272.4 640 264.2 640 255.1z" />
                    </svg>
                </Link>

                <Link activeClass="bg-cyan-500" to="portfolio" spy={true} smooth={true} duration={1500} className={'mx-4 cursor-pointer overflow-hidden rounded-full p-2 transition-all duration-500'}>
                    <svg baseProfile="tiny" id="Layer_1" version="1.2" viewBox="0 0 24 24" width={28} height={28}>
                        <path fill='#fff' d="M12,20c-0.195,0-0.391-0.057-0.561-0.172c-0.225-0.151-5.508-3.73-7.146-5.371C2.462,12.626,2.25,10.68,2.25,9.375   C2.25,6.411,4.661,4,7.625,4C9.427,4,11.023,4.891,12,6.256C12.977,4.891,14.573,4,16.375,4c2.964,0,5.375,2.411,5.375,5.375   c0,1.305-0.212,3.251-2.043,5.082c-1.641,1.641-6.923,5.22-7.146,5.371C12.391,19.943,12.195,20,12,20z M7.625,6   C5.764,6,4.25,7.514,4.25,9.375c0,1.093,0.173,2.384,1.457,3.668c1.212,1.212,4.883,3.775,6.293,4.746   c1.41-0.971,5.081-3.534,6.293-4.746c1.284-1.284,1.457-2.575,1.457-3.668C19.75,7.514,18.236,6,16.375,6S13,7.514,13,9.375   c0,0.552-0.447,1-1,1s-1-0.448-1-1C11,7.514,9.486,6,7.625,6z" />
                    </svg>
                </Link>
                { isContactFormEnabled && (
                    <Link activeClass="bg-cyan-500" to="contactme" spy={true} smooth={true} duration={1500} className={'mx-4 cursor-pointer overflow-hidden rounded-full p-2 transition-all duration-500'}>
                    <svg data-name="Layer 1" id="Layer_1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" width={28} height={28}>
                        <path fill='#fff' d="M476,236.69l.21-.37c.15-.28.3-.57.43-.87a13.69,13.69,0,0,0,.69-1.74c0-.13.07-.26.11-.39.1-.34.2-.68.27-1s.09-.49.13-.74.08-.47.11-.71.06-.73.08-1.09c0-.13,0-.25,0-.38a16,16,0,0,0-.25-3c-.05-.22-.11-.43-.16-.65s-.11-.5-.18-.75-.25-.77-.39-1.15c0-.07,0-.14-.07-.22-.15-.38-.32-.74-.5-1.11a2,2,0,0,1-.1-.21c-.17-.33-.36-.65-.55-1-.07-.1-.12-.2-.18-.3-.18-.27-.37-.53-.56-.79l-.3-.42c-.17-.21-.35-.41-.53-.61s-.29-.34-.44-.5l-.52-.49c-.18-.17-.36-.35-.55-.51s-.42-.33-.63-.49a6.54,6.54,0,0,0-.52-.4,15.52,15.52,0,0,0-2.64-1.48L56.75,36.1a15,15,0,0,0-16,2.6,15,15,0,0,0-5.44,18.58L216.17,468.11a1.19,1.19,0,0,0,.08.16c.16.37.34.72.53,1.07l.18.32c.18.3.36.58.55.87l.17.25c.25.35.5.69.78,1l0,0c.3.36.62.7.95,1a.27.27,0,0,1,.08.07c.29.28.59.54.9.8l.26.2c.28.22.56.43.85.63l.26.17c.34.22.7.43,1.07.63l.43.22.74.34.5.21c.32.12.65.24,1,.34l.35.1c.39.11.78.2,1.17.28l.35.07c.41.07.83.13,1.24.16l.1,0h0c.41,0,.82,0,1.23,0,.24,0,.49,0,.74,0s.49,0,.73,0l.49-.07c.33-.05.67-.09,1-.16l.43-.1c.35-.08.7-.17,1-.28l.43-.14c.33-.12.67-.24,1-.38l.19-.08.28-.14a9,9,0,0,0,.82-.41l.54-.3.65-.41c.2-.14.4-.27.59-.42a6.4,6.4,0,0,0,.51-.4c.21-.16.41-.33.61-.51s.29-.27.43-.41l.57-.57.44-.51c.15-.18.31-.36.46-.56s.37-.51.54-.77.17-.24.25-.37a14.85,14.85,0,0,0,2.06-5.3c.09-.5.16-1,.2-1.52L261.83,261,464,244.13h.22l.09,0c.42,0,.83-.09,1.24-.16l.36-.07q.57-.1,1.14-.27l.35-.1c.34-.11.68-.23,1-.36a4.4,4.4,0,0,0,.5-.21c.25-.1.49-.21.74-.33l.44-.23a11.53,11.53,0,0,0,1.07-.63l.24-.16c.29-.2.58-.41.86-.63l.26-.21c.3-.24.58-.5.86-.76l.13-.12a15.74,15.74,0,0,0,1.75-2.06l.18-.27C475.6,237.26,475.78,237,476,236.69ZM246.64,232a15,15,0,0,0-13.75,13.75l-13,156L77.22,77.82l324.9,141.26Z" />
                    </svg>
                </Link>)}

            </div>
        </div>
  )
}

export default FloatingMenu
