import PortfolioCard from './portfolio_card'

const PortfolioSection = () => {
  type PortfolioCardType = {
    type: string,
    title: string,
    image: string,
    tecnologies: string[],
    description: string,
    link: string,
    github: string
  }

  const projects: PortfolioCardType[] = [
    {
      type: 'web',
      title: 'Tic Tac Toe',
      image: './projects/tictactoe.png',
      tecnologies: ['reactjs', 'typescript', 'html', 'tailwindcss'],
      description: '',
      link: 'https://tictactoe.crisvega.dev/',
      github: ''
    },
    {
      type: 'web',
      title: 'Rock, Paper, Scissors',
      image: './projects/rps.png',
      tecnologies: ['reactjs', 'typescript', 'html', 'tailwindcss'],
      description: '',
      link: 'https://rpsgame.crisvega.dev/',
      github: ''
    },
    {
      type: 'web',
      title: 'Wordle NextJS',
      image: './projects/wordle.png',
      tecnologies: ['nextjs', 'typescript', 'html', 'tailwindcss'],
      description: '',
      link: 'https://wordle.crisvega.dev/',
      github: ''
    }
    // ,
    // {
    //   type: 'web',
    //   title: 'Suscripcion Digital',
    //   image: './projects/sd.png',
    //   tecnologies: ['javascript', 'php', 'laravel', 'tailwindcss', 'html'],
    //   description: '',
    //   link: 'https://sd.lavozdemichoacan.com.mx/',
    //   github: ''
    // },
    // {
    //   type: 'web',
    //   title: 'La Voz Pack',
    //   image: './projects/pack.png',
    //   tecnologies: ['html', 'php', 'tailwindcss', 'javascript'],
    //   description: '',
    //   link: 'http://lavozpack.com/',
    //   github: ''
    // }
  ]

  return (
    <section id='portfolio'>
      <h2 className='mb-8 mt-12 text-center text-4xl font-bold text-white'>Recent Portfolio</h2>
      <div className="mx-auto flex max-w-4xl flex-wrap justify-center">

        {projects.map((project: PortfolioCardType, index: number) => (
          <PortfolioCard
            key={index}
            title={project.title}
            image={project.image}
            tecnologies={project.tecnologies}
            demo={project.link}
          />
        ))}

      </div>
    </section>
  )
}

export default PortfolioSection
