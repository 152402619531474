const SkillItem = ({ skill, level, url }: {
    skill: string;
    level: string;
    url: string;
}) => {
  return (
    <div className='mx-4 my-6 flex w-32 cursor-pointer flex-col items-center justify-between rounded-2xl bg-black bg-opacity-10 p-2 px-4 lg:mx-10 lg:w-auto lg:flex-row lg:justify-start lg:overflow-hidden lg:rounded-none lg:text-left'>
        <img src={url} alt="" className='h-16 w-16'/>
        <div className='ml-4 mt-4 text-center lg:text-left'>
            <h3 className='font-bold text-white'>{ skill }</h3>
            <p className='text-white'>{ level }</p>
        </div>
    </div>
  )
}

export default SkillItem
