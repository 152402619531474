import InputItem from './input_item'
import { getFirestore, collection, addDoc } from 'firebase/firestore'
import { FirebaseApp } from 'firebase/app'
import { useState } from 'react'

const ContactMeSection = ({ firebase, isEnabled }:{
  firebase: FirebaseApp,
  isEnabled: boolean,
}) => {
  const [inputNameValue, setInputNameValue] = useState('')
  const [inputEmailValue, setInputEmailValue] = useState('')
  const [inputSubjectValue, setInputSubjectValue] = useState('')
  const [inputMessageValue, setInputMessageValue] = useState('')
  const [isSending, setIsSending] = useState(false)
  const [isSent, setIsSent] = useState(false)
  const db = getFirestore(firebase)

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    setIsSending(true)

    setTimeout(async () => {
      const data = new FormData(e.target)
      const formObject = Object.fromEntries(data.entries())

      if (formObject.name.toString().length < 3) return false
      if (formObject.email.toString().length < 3) return false
      if (formObject.subject.toString().length < 3) return false
      if (formObject.message.toString().length < 3) return false

      console.log(db)
      console.log(setIsSent, collection, addDoc)

      try {
        await addDoc(collection(db, 'messages'), {
          name: formObject.name.toString(),
          email: formObject.email.toString(),
          subject: formObject.subject.toString(),
          message: formObject.message.toString()
        }).then(() => {
          setIsSending(false)
          setIsSent(true)
          setInputNameValue('')
          setInputEmailValue('')
          setInputSubjectValue('')
          setInputMessageValue('')

          setTimeout(() => {
            setIsSent(false)
          }, 2500)
        })
      } catch (e) {
        console.error(e)
      }
    }, 1000)
  }

  const setInputNameValueHandle = (e: any) => setInputNameValue(e.target.value)
  const setInputEmailValueHandle = (e: any) => setInputEmailValue(e.target.value)
  const setInputSubjectValueHandle = (e: any) => setInputSubjectValue(e.target.value)
  const setInputMessageValueHandle = (e: any) => setInputMessageValue(e.target.value)

  return isEnabled
    ? (
     <section id='contactme'>

      <div className="mx-auto my-10 w-full max-w-2xl py-8">
        <h2 className="text-center text-4xl font-bold text-white">Contact Me</h2>

        {isSending
          ? <div className='flex w-full flex-col items-center justify-center p-4'>
              <div className='flex h-12 w-12 items-center justify-center rounded-full bg-green-500'>
                <svg className='h-6 w-6 animate-spin text-white' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' aria-hidden='true'>
                  <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M13 10V3L4 14h7v7l9-11h-7z' />
                </svg>
              </div>
            <p className='mt-2 text-sm text-white'>{'Sending your message!'}</p>
            </div>
          : <></>}

         {isSent
           ? <div className='flex flex-col items-center justify-center rounded-lg p-4'>
              <div className='flex h-12 w-12 items-center justify-center rounded-full bg-green-500'>
                <svg className='h-6 w-6 text-white' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' aria-hidden='true'>
                  <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M5 13l4 4L19 7' />
                </svg>
              </div>
              <p className='mt-2 text-sm text-white'>{'Message sent!'}</p>
            </div>
           : <></>}

            {isSending || isSent
              ? <></>
              : <form className={'mt-14'} onSubmitCapture={handleSubmit} method='POST'>
                  <div className="">
                    <InputItem type="text" placeholder="Name" name="name" value={inputNameValue} onChange={(e) => setInputNameValueHandle(e)} />
                    <InputItem type="email" placeholder="Email" name="email" value={inputEmailValue} onChange={(e) => setInputEmailValueHandle(e)} />
                    <InputItem type="text" placeholder="Subject" name="subject" value={inputSubjectValue} onChange={(e) => setInputSubjectValueHandle(e)} />
                    <InputItem type="textarea" placeholder="Message" name="message" value={inputMessageValue} onChange={(e) => setInputMessageValueHandle(e)} />

                    <div className="mt-4 flex flex-wrap justify-center">
                      <button
                        data-sitekey="6Letb3seAAAAANPG2hSxvtKvatzZEJzFbaGRRSin"
                        data-callback='onSubmit'
                        data-action='submit'
                        className="w-2/5 rounded border-2 border-white py-2 px-4 font-bold text-white focus:outline-none"
                        type="submit" >
                        Send Message
                      </button>
                    </div>
                  </div>
              </form>
            }

      </div>
    </section>
      )
    : (<></>)
}

export default ContactMeSection
