const HeadSection = ({ ppIsEnabled }:{
  ppIsEnabled: boolean
}) => {
  return (
    ppIsEnabled
      ? <section className="head flex w-full items-center justify-center" id="home">
        <div className="my-40 mx-10 rounded-2xl border-2 border-cyan-200 p-10 text-center backdrop-blur-2xl">
       <img
            src={'./pp.jpg'}
            alt=""
            className="mx-auto w-64 rounded-full "
          /> : <></>
          <h1 className="mt-4 text-4xl font-bold text-cyan-300">CRISTIAN VEGA</h1>
          <p className="mt-2 text-2xl  text-teal-300">Full Stack Developer</p>
        </div>
    </section>
      : <section className="head flex w-full items-center justify-center" id="home">
      <div className="my-40 mx-10 rounded-2xl border-2 border-cyan-200 p-10 text-center backdrop-blur-2xl">
        <h1 className="mt-4 text-4xl font-bold text-cyan-300">CRISTIAN VEGA</h1>
        <p className="mt-2 text-2xl  text-teal-300">Full Stack Developer</p>
      </div>
  </section>
  )
}

export default HeadSection
